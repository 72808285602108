<template>
  <v-container fill-height fluid justify-center>
    <div class="sheet-size">
      <v-img
        src="../../../../../assets/images/shop-effect.png"
        class="img-size d-flex justify-center ma-auto"
      >
      <v-btn
        outlined
        elevation="0"
        color="primary-red"
        class="btn-size text-body-2"
        @click="() => $router.push('/entering')"
      >입점문의</v-btn>
      <v-btn
        outlined
        elevation="0"
        color="primary-blue"
        class="btn-size text-body-2 ml-2"
        @click="() => $router.push('/franchise')"
      >프랜차이즈문의</v-btn>
      </v-img>
        
    </div>
    
    <!-- <v-row justify-center align-center class="contents-box">
      <v-col cols="12" md="6"
        :order="$vuetify.breakpoint.mdAndUp ? 1 : 2"
        class="d-flex align-center"
        :class="{'justify-center': $vuetify.breakpoint.mdAndDown, 'justify-start': $vuetify.breakpoint.lgAndUp}"
      >
        <div 
          class="lightly-dark--text text-h6 text-md-h4 font-weight-black"
          :class="{'text-center': $vuetify.breakpoint.smAndDown}"
        >
          <div class="lightly-dark--text align-center">
            <v-col class="lightly-dark--text pa-0 d-flex align-center justify-start"
              :class="{'justify-center' : $vuetify.breakpoint.smAndDown}"
            >
              <div>'</div>
              <v-img 
                src="@/assets/logo/logo_text.png" 
                :max-height="$vuetify.breakpoint.mdAndUp ? 32 : 21" 
                :max-width="$vuetify.breakpoint.mdAndUp ? 269.7161 : 177.0012"
              >
              </v-img>  
              <div>'</div>
            </v-col>
          </div>
          <div class="lightly-dark--text mt-md-4"
            :class="{'mt-1' : $vuetify.breakpoint.smAndDown}"
          >프랜차이즈로</div>
          <div class="lightly-dark--text mt-md-4"
            :class="{'mt-1' : $vuetify.breakpoint.smAndDown}"
          >우리 건물을 활성화시켜요.</div>
          <div class="lightly-dark--text text-subtitle-2 text-md-h6 font-weight-black mt-md-6"
            :class="{'mt-2' : $vuetify.breakpoint.smAndDown}"
          >자동결제 시스템</div>
          <div class="lightly-dark--text text-subtitle-2 text-md-h6 font-weight-black mt-md-1"
          >1,000여개 협력사 혜택</div>
          <div class="lightly-dark--text text-subtitle-2 text-md-h6 font-weight-black mt-md-1"
          >공동 협력사 혜택∙마케팅∙관리</div>
          <div class="mt-md-6 justify-start">
            <v-btn
              :width="$vuetify.breakpoint.mdAndUp ? 160 : 140"
              :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
              outlined
              color="primary-red"
              elevation="0"
              class="round_box text-h7 font-weight-bold  ma-auto"
              :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
              @click="() => $router.push('/entering')"
            >입점신청
            </v-btn>
            <v-btn
              :width="$vuetify.breakpoint.mdAndUp ? 160 : 140"
              :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
              outlined
              color="primary-blue"
              elevation="0"
              class="round_box text-h7 font-weight-bold  ma-auto ml-4"
              :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
              @click="() => $router.push('/franchise')"
            >프랜차이즈 문의
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" :order="$vuetify.breakpoint.mdAndUp ? 2 : 1">
        <v-img 
          src="@/assets/images/franchise_up.png" 
          :max-width="$vuetify.breakpoint.smAndDown ? '260px' : '780px'"
          max-height="600" 
          :class="{'mx-auto': $vuetify.breakpoint.smAndDown}" 
        />
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
export default {
  // data () {
  //   return {
  //   }
  // },
  // name: 'MainFranchiseSection',
  // methods: {
  //   navigateTo(url) { 
  //     this.$router.push(url)
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.contents-box {
  max-width: 1000px !important;
}
.round_box {
  border-radius: 8px;
  width: 160px !important;
  height: 40px !important;
}
.img-size {
  height: 100%;
  width: 100%;
  border-radius: 25px !important;
}
.sheet-size {
  width: 1200px;
  height: 600px;
  border-radius: 25px !important;
  margin-top: -40px;
}
.btn-size {
  border-radius: 8px;
  width: 160px !important;
  height: 40px !important;
  top: 71%;
  left: 8%;
}
</style>